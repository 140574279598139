import { useStaticQuery, graphql } from "gatsby"

export const useNumbers = () => {
  const { img1, img2, img3, img4, img5 } = useStaticQuery(
    graphql`
      query Numbers {
        img1: file(relativePath: { eq: "gridmarketing__01@2x.png" }) {
          src: childImageSharp {
            fixed(width: 250) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        img2: file(relativePath: { eq: "gridmarketing__02@2x.png" }) {
          src: childImageSharp {
            fixed(width: 250) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        img3: file(relativePath: { eq: "gridmarketing__03@2x.png" }) {
          src: childImageSharp {
            fixed(width: 250) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        img4: file(relativePath: { eq: "gridmarketing__04@2x.png" }) {
          src: childImageSharp {
            fixed(width: 250) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        img5: file(relativePath: { eq: "gridmarketing__05@2x.png" }) {
          src: childImageSharp {
            fixed(width: 250) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `
  )

  return {
    img1: img1.src.fixed,
    img2: img2.src.fixed,
    img3: img3.src.fixed,
    img4: img4.src.fixed,
    img5: img5.src.fixed
  }
}
