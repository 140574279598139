import React from "react"
import styled from "styled-components"
import { ACCENT_COLOR } from "../consts/color"

const TableContainer = styled.div`
position: relative;
  margin: 0 -15px;
  overflow: auto;
  display: grid;
  grid-template-columns: 50% 50% 50% 50%;
  scroll-snap-type: x proximity;
  grid-column-start: 2;
  @media all and (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`
const Header = styled.div`
  text-align: center;
  color: #fff;
  font-size: 1.375em;
  font-weight: 700;
  background-color: ${ACCENT_COLOR};
  align-self: stretch;
  padding: 15px;

  & > div {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.primary {
    color: #fcee21;
  }

  &.fixed {
    display: none;

    @media all and (min-width: 768px) {
      display: inherit;
    }
  }

  &.mobile {
    position: relative;
    @media all and (min-width: 768px) {
      display: none;
    }
  }
`
const Cell = styled.div`
  padding: 0.9375rem;
  border-bottom: 1px solid #f2f2f2;
  scroll-snap-align: end;

  &.first-column {
    border-right-style: double;
    border-right-color: ${ACCENT_COLOR};
    border-bottom: 1px solid #f2f2f2;
    font-size: 1.375em;
  }

  &.primary-column {
    background-color: #e9eaf1;
  }

  &.last-row {
    border-bottom: 2px solid ${ACCENT_COLOR};
  }

  &.mobile {
    position: relative;
    @media all and (min-width: 768px) {
      display: none;
    }
  }
`
const FullRow = styled.div`
  width: 100%;
  grid-column: span 4;
  text-align: right;
  font-size: 0.8125em;
  padding: 0.3125rem 1.5rem 0.3125rem 0;
  border-bottom: 2px solid ${ACCENT_COLOR};
`
const Strong = styled.span`
  font-weight: 700;
`

const Table = () => {
  return (
    <TableContainer>
      <Header>
        <div>Szempontok</div>
      </Header>
      <Header>
        <div>Hagyományos keresési hirdetések</div>
      </Header>
      <Header>
        <div>Dinamikus keresési hirdetések</div>
      </Header>
      <Header className="primary">
        <div>Adatvezérelt Google Ads keresési hirdetések</div>
      </Header>

      <FullRow>(egyedi fejlesztésű, adatbázis-vezérelt)</FullRow>

      <Cell className="first-column">kulcsszavak</Cell>
      <Cell>
        <Strong>manuálisan kell megadni a kulcsszókészletet</Strong>
      </Cell>
      <Cell>
        <Strong>a Google határozza meg a lehetséges kulcsszókészletet</Strong>
      </Cell>
      <Cell className="primary-column">
        <p>
          a kulcsszavak{" "}
          <Strong>
            a termékparaméterek szinte végtelen számú, általunk meghatározott
            kombinációi
          </Strong>
        </p>{" "}
        (pl: szín + típus + terméknév => fekete gyerek póló)
      </Cell>

      <Cell className="first-column">készletkövetés</Cell>
      <Cell>
        <Strong>nincs készletkövetés</Strong>
      </Cell>
      <Cell>
        <p>
          <Strong>nincs automatizált készletkövetés</Strong>
        </p>
        (A Google a webáruházban megjeleníthető termékeket veszi alapul)
      </Cell>
      <Cell className="primary-column">
        <p>
          <Strong>automatizált készletkövetés</Strong>
        </p>
        (igény szerint 6, 12 vagy 24 óránkénti automatikus frissítés a
        készletadatok alapján; egyedi, személyre szabott szabályok lehetősége
        (pl. csak adott készletszint vagy választékszint felett legyen a termék
        hirdetve))
      </Cell>

      <Cell className="first-column">hirdetés szövegezés</Cell>
      <Cell>
        <p>
          <Strong>manuálisan megírt hirdetési szövegek</Strong>
        </p>
        <Strong>differenciálás csak korlátozottan lehetséges</Strong>
      </Cell>
      <Cell>
        <Strong>a Google legenerálja a hirdetési szövegeinket,​</Strong> a
        weboldal tartalma alapján
      </Cell>
      <Cell className="primary-column">
        <p>
          <Strong>
            ügyfélre szabott, specialisták által kézzel írott szövegek
          </Strong>
        </p>
        <Strong>termékparaméterek mentén teljes differenciálás</Strong>
      </Cell>

      <Cell className="first-column">érkezési oldal</Cell>
      <Cell>
        <Strong>mi határozzuk meg</Strong> (a felhasználó keresési kifejezésének
        megfelelően)
      </Cell>
      <Cell>
        <Strong>a Google határozza meg</Strong> (kulcsszókészletet
        összepárosítja a számára leginkább releváns landoló weblapokkal)
      </Cell>
      <Cell className="primary-column">
        <Strong>mi határozzuk meg</Strong> ​(a felhasználó keresési
        kifejezésének megfelelően)
      </Cell>

      <Cell className="first-column">hirdetés hitelessége</Cell>
      <Cell>
        előfordulhat, hogy a megjelent hirdetés mögött már{" "}
        <Strong>
          nincs is készleten lévő termék, a szövegezés pontatlan lehet
        </Strong>
        ​
      </Cell>
      <Cell>
        előfordulhat, hogy a megjelent hirdetés mögött már{" "}
        <Strong>
          ​nincs is készleten lévő termék, a szövegezés pontatlan lehet
        </Strong>
      </Cell>
      <Cell className="primary-column">
        <Strong>
          csak készleten lévő termék esetén jelenik meg hirdetés, a hirdetés
          szövegezése pontos, a felhasználó kereséséhez igazodik
        </Strong>
      </Cell>

      <Cell className="first-column">célzás</Cell>
      <Cell>
        <Strong>manuálisan kell beállítani a célzást</Strong>
      </Cell>
      <Cell>
        <Strong>a Google határozza meg​</Strong> a weboldal szövegezése alapján
      </Cell>
      <Cell className="primary-column">
        <Strong>tűpontos célzás</Strong> ​(akár több ezer kifejezésre
        külön-külön, egyedileg készült célzás)
      </Cell>

      <Cell className="first-column">kontroll</Cell>
      <Cell>
        <Strong>teljes kontroll a kezedben</Strong> ​(a hirdetéslétrehozás,
        hirdetéskezelés és teljesítményadatok vizsgálata során is)
      </Cell>
      <Cell>
        <Strong>nagyon korlátozott kontroll​</Strong>, átadod a Google-nek (nem
        lehet belenyúlni a hirdetésekbe)
      </Cell>
      <Cell className="primary-column">
        <Strong>teljes kontroll a kezedben</Strong> ​(a hirdetéslétrehozás,
        hirdetéskezelés és teljesítményadatok vizsgálata során is)
      </Cell>

      <Cell className="first-column">költségoptimal izáció</Cell>
      <Cell>
        pontosan be lehet állítani, hogy mire szeretnéd költeni a pénzed,​
        <Strong>súlyozni tudod a költéseket</Strong>
      </Cell>
      <Cell>
        <Strong>a Google határozza meg​</Strong>, melyik termék milyen
        intenzitással jelenjen meg
      </Cell>
      <Cell className="primary-column">
        pontosan be lehet állítani, hogy mire szeretnéd költeni a pénzed,​
        <Strong>súlyozni tudod a költéseket</Strong>
      </Cell>

      <Cell className="first-column">mérési adatok</Cell>
      <Cell>
        <Strong>
          pontos mérési adatok a hirdetett termékpaletta minden szintjén
        </Strong>
      </Cell>
      <Cell>
        <Strong>korlátozottan lekérhető, nehezen szűrhető adatok</Strong>
      </Cell>
      <Cell className="primary-column">
        <Strong>
          pontos mérési adatok a hirdetett termékpaletta minden szintjén
        </Strong>
      </Cell>

      <Cell className="first-column last-row">optimalizáció</Cell>
      <Cell className="last-row">
        a mérési adatok ismeretében{" "}
        <Strong>
          folyamatos, akár egyedi szabályokhoz kötött optimalizáció
        </Strong>
      </Cell>
      <Cell className="last-row">
        <Strong>korlátozott beleszólás​</Strong> a hirdetések optimalizációjába
      </Cell>
      <Cell className="primary-column last-row">
        a mérési adatok ismeretében{" "}
        <Strong>
          folyamatos, akár egyedi szabályokhoz kötött optimalizáció
        </Strong>
      </Cell>
    </TableContainer>
  )
}

export default Table
