import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { ACCENT_COLOR } from "../../consts/color"

const StyledSection = styled.section`
  padding: 3.25rem 0;

  @media all and (min-width: 768px) {
    padding: 6.25rem 0;
  }
`
const Container = styled.div`
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;
`
const HeaderContainer = styled.div`
  text-align: center;
  margin-bottom: 3.25rem;

  @media all and (min-width: 768px) {
    margin-bottom: 6.25rem;
  }
`
const SectionTitle = styled.h2`
  font-size: 2.125em;
  font-weight: 400;
  margin-bottom: 0.65rem;
`
const SecondaryTitle = styled.h3`
  font-size: 1em;
  font-weight: 700;
  text-transform: uppercase;
  color: ${ACCENT_COLOR};
  margin-bottom: 0;
`
const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2.5rem;

  @media all and (min-width: 769px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 3.75rem;
  }
`
const List = styled.div`
  display: grid;
  grid-column-gap: 0.625rem;
  grid-row-gap: 0.625rem;
  grid-template-columns: 16px 1fr;
`
const ListItem = styled.p`
  margin-bottom: 0;

  &.strong {
    font-weight: 700;
  }
`
const Bullet = styled(Img)`
  margin-top: 3px;
`
const About = ({ img, bullet }) => {
  return (
    <StyledSection>
      <Container>
        <HeaderContainer>
          <SectionTitle>Miért bennünket válassz,</SectionTitle>
          <SecondaryTitle>
            ha professzionális, ​adatbázis-vezérelt​ Google Ads hirdetéseket
            szeretnél?
          </SecondaryTitle>
        </HeaderContainer>
        <ContentContainer>
          <Img
            fluid={img}
            alt="Professzionális, ​adatbázis-vezérelt​ google ads hirdetések"
          />
          <List>
            <Bullet fixed={bullet} />
            <ListItem>
              Több éves szakmai tapasztalattal specialisták vagyunk
              csúcskategóriás Google Ads kampányok menedzselésében és
              fejlesztésében.
            </ListItem>
            <Bullet fixed={bullet} />
            <ListItem className="strong">
              Európai szinten is egyedülálló automatizált rendszert
              fejlesztettünk, amivel ügyfeleink hatékonyan vonzhatnak be még
              több érdeklődőt webáruházaikba.
            </ListItem>
            <Bullet fixed={bullet} />
            <ListItem>
              Egyedi, ügyfélre szabott megoldásokban gondolkodunk, nem
              sablonokban.
            </ListItem>
            <Bullet fixed={bullet} />
            <ListItem className="strong">
              Azon dolgozunk, hogy minél jobban megismerjük ügyfeleink igényeit,
              elvárásait.
            </ListItem>
            <Bullet fixed={bullet} />
            <ListItem>
              A kampányok indulását megelőzően mindent pontosan megtervezzünk.
            </ListItem>
            <Bullet fixed={bullet} />
            <ListItem className="strong">
              Az ügyfelekkel folyamatosan egyeztetünk a közös munka során. Előre
              megbeszéljük azt is, hogy az ügyfelek milyen adatokat szeretnének
              látni hónapról-hónapra, majd egyedi igények szerinti, könnyen
              érthető és átlátható eredménykimutatásokat készítünk részükre.
            </ListItem>
            <Bullet fixed={bullet} />
            <ListItem>
              A kampányok költségoptimalizálására nagy hangsúlyt fektetünk.
            </ListItem>
            <Bullet fixed={bullet} />
            <ListItem className="strong">
              A kampányok eredményességét folyamatosan figyeljük és szükség
              szerint módosításokat javaslunk.
            </ListItem>
          </List>
        </ContentContainer>
      </Container>
    </StyledSection>
  )
}

export default About
