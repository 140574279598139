import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

import { ACCENT_COLOR } from "../../consts/color"

const StyledSection = styled.section`
  padding: 3.125em 0 5.3125em 0;
`

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
`

const TitleContainer = styled.div`
  text-align: center;
  padding: 0 0 3.25em 0;

  @media all and (min-width: 768px) {
    padding: 3.125em 0 6.25em 0;
  }
`

const MainTitle = styled.h1`
  font-size: 2.325em;
  font-weight: 400;
  margin-bottom: 0.625em;
`

const SecondaryTitle = styled.h2`
  font-size: 1em;
  font-weight: 700;
  text-transform: uppercase;
  color: ${ACCENT_COLOR};
  margin-bottom: 0;
`

const DescriptionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2.5rem;

  @media all and (min-width: 769px) {
    grid-template-columns: 1fr 1fr;
  }
`
const Description = styled.div``

const Strong = styled.span`
  font-weight: 700;
`

const Video = styled.iframe`
  border: none;
  margin-bottom: 0;
  width: 100%;
  height: 194px;

  @media all and (min-width: 768px) {
    height: 315px;
  }
`

const Hero = ({ img }) => {
  return (
    <StyledSection>
      <Container>
        <TitleContainer>
          <MainTitle>
            Egyedi, adatvezérelt Google Ads keresési hirdetések
          </MainTitle>
          <SecondaryTitle>WEBSHOP ÜGYFÉLSZERZÉS MESTERFOKON</SecondaryTitle>
        </TitleContainer>
        <DescriptionContainer>
          <Video
            width="560"
            height="315"
            src="https://www.youtube-nocookie.com/embed/4MGCEMFa3yw"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
          <Description>
            <p>
              Adott egy jól megalapozott üzleti koncepció, széles termékpaletta
              és egy jól felépített webshop, amely mögött készlet is
              rendelkezésre áll. Na de hogyan tovább?
            </p>
            <p>
              Tapasztalataink alapján a webáruházak többsége online hirdetések
              nélkül egyszerűen nem veheti fel a versenyt a konkurenciával. Az
              internetes felhasználók közel 93%-a ha valamit keres, egyből{" "}
              <Strong>a ​Google keresőjéhez fordul​.</Strong> Ahhoz, hogy a
              keresgélők pont a mi oldalunkon találják meg, amire vágynak, ahhoz
              elengedhetetlenek a megfelelően célzott, úgynevezett{" "}
              <Strong>​Google Ads hirdetések​.</Strong>
            </p>
            <p>
              Cégünk ​olyan <Strong>egyedi rendszert fejlesztett,</Strong>​
              amellyel a lehető legmagasabb szintre emelhetjük{" "}
              <Strong>
                ​webshopod ügyfélszerzési hatékonyságát​, ​adatvezérelt Google
                Ads keresési hirdetések által!
              </Strong>
              ​
            </p>
          </Description>
        </DescriptionContainer>
      </Container>
    </StyledSection>
  )
}

export default Hero
