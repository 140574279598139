import React, { useState } from "react"
import styled from "styled-components"
import { navigate } from "gatsby-link"

import Background from "../../images/gridmarketing__bg__contact@2x.png"
import { ACCENT_COLOR } from "../../consts/color"

const StyledSection = styled.section`
  padding: 4.6875rem 0;
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`
const Container = styled.div`
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;
`
const HeaderContainer = styled.div`
  text-align: center;
  margin-bottom: 3.125rem;
`
const SectionTitle = styled.h2`
  font-size: 2.125em;
  font-weight: 400;
  margin-bottom: 0;
`
const SecondaryTitle = styled.h3`
  font-size: 1em;
  font-weight: 700;
  text-transform: uppercase;
  color: ${ACCENT_COLOR};
  margin-bottom: 0.65rem;
`
const Form = styled.form`
  max-width: 560px;
  margin: 0 auto;
  text-align: center;
`
const StyledInput = styled.input`
  padding: 1rem 1.25rem;
  width: 100%;
  margin-bottom: 1.875rem;
  border: 1px solid #cccccc;

  &:focus {
    outline: none;
  }
`
const StyledTextArea = styled.textarea`
  padding: 1rem 1.25rem;
  width: 100%;
  min-width: 290px;
  max-width: 345px;
  min-height: 255px;
  max-height: 255px;
  border: 1px solid #cccccc;

  &:focus {
    outline: none;
  }

  @media all and (min-width: 768px) {
    max-width: 560px;
  }
`
const Button = styled.button`
  display: inline-block;
  color: #fff;
  background-color: ${ACCENT_COLOR};
  font-size: 1.275em;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 700;
  margin-top: 3.75rem;
  padding: 1rem 1.875rem;
  cursor: pointer;
  border: none;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
`

const CheckboxLabel = styled.label`
  margin-top: 20px;
  display: flex;
  align-items: center;
`
const Checkbox = styled.input`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const Contact = () => {
  const [state, setState] = useState({})

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }

  return (
    <StyledSection id="kapcsolat">
      <Container>
        <HeaderContainer>
          <SecondaryTitle>
            Szeretnéd professzionális szintű, adatbázis-vezérelt Google Ads
            hirdetésekkel fellendíteni webshopod forgalmát?
          </SecondaryTitle>
          <SectionTitle>Kérj személyre szabott ajánlatot!</SectionTitle>
        </HeaderContainer>
        <Form
          name="kapcsolat"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
          action="/koszonjuk"
        >
          <input
            type="hidden"
            name="form-name"
            value="contact"
            onChange={handleChange}
          />
          <StyledInput
            name="name"
            placeholder="Név"
            type="text"
            onChange={handleChange}
            required
          />
          <StyledInput
            name="email"
            placeholder="Email"
            type="email"
            onChange={handleChange}
            required
          />
          <StyledInput
            name="phone"
            placeholder="Telefonszám"
            type="tel"
            onChange={handleChange}
            required
          />
          <StyledTextArea
            name="message"
            placeholder="Üzenet (Opcionális)"
            onChange={handleChange}
          />
          <CheckboxLabel htmlFor="gdpr">
            <Checkbox
              type="checkbox"
              name="gdpr"
              title="Elfogadom az adatvédelmi tájékoztatást"
              onChange={handleChange}
              required
            />
            <a target="_blank" href="/files/adatvedelmi-gridmarketing.pdf">
              Elfogadom az adatvédelmi nyilatkozatot
            </a>
          </CheckboxLabel>
          <Button type="submit">Ajánlatkérés</Button>
        </Form>
      </Container>
    </StyledSection>
  )
}

export default Contact
