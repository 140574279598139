import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { useNumbers } from "../../hooks/numbers"

const StyledSection = styled.section`
  padding: 3.25rem 0;

  @media all and (min-width: 768px) {
    padding: 6.25rem 0;
  }
`
const Container = styled.div`
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;
`
const SectionTitle = styled.h2`
  font-size: 2.125em;
  font-weight: 400;
  margin-bottom: 3.125rem;
  text-align: center;
`
const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;

  @media all and (min-width: 769px) {
    grid-template-columns: 3fr 6fr 3fr;
  }
`
const BlockTitle = styled.h3`
  font-size: 1.375em;
  font-weight: 700;
  margin-bottom: 1rem;
`
const CenterRow = styled.div`
  align-self: center;

  @media all and (min-width: 769px) {
    grid-column: 2/3;
  }
`
const Number = styled(Img)`
  align-self: center;
  justify-self: center;

  @media all and (min-width: 769px) {
    grid-column: 1/2;

    &.right {
      grid-column: 3;

      &.second-row {
        grid-row: 2;
      }
      &.fourth-row {
        grid-row: 4;
      }
    }
  }
`

const Reasons = () => {
  const { img1, img2, img3, img4, img5 } = useNumbers()
  return (
    <StyledSection id="miert-valaszd">
      <Container>
        <SectionTitle>
          5 érv az általunk fejlesztett, egyedi rendszer mellett
        </SectionTitle>
        <ContentContainer>
          <Number fixed={img1} />
          <CenterRow>
            <BlockTitle>Teljes termékkínálat részletes lefedése</BlockTitle>
            <p>
              Széles termékkínálatú webshopok Google Ads hirdetéseit a
              legmagasabb szintre emeljük. Nincs kompromisszum a precizitásban
              egyszerűen azért, mert a készlet sokszínű.
            </p>
          </CenterRow>
          <Number fixed={img2} className="right second-row" />
          <CenterRow>
            <BlockTitle>Automatizált készletkövetés</BlockTitle>
            <p>
              A rendszer előre meghatározott időközönként (6,12 vagy 24 óra)
              lekéri a termékek friss adatbázisát, amelyben az aktuális
              terméklisták és termékparaméterek szerepelnek. Csak a friss
              terméklista alapján futnak a hirdetések, megszűnik a nem
              rendelhető termékekre pazarolt költség. Új termék esetén a
              meghatározott definíciók alapján a rendszer automatikusan
              létrehozza a hirdetéseket.
            </p>
          </CenterRow>
          <Number fixed={img3} />
          <CenterRow>
            <BlockTitle>Manuális setup</BlockTitle>
            <p>
              Speciálisan ügyfélre szabott, manuális beállítások a hirdetések
              megkezdése előtt. Ennek része az egyedi kampánytervezés, személyre
              szabott szabályok, különleges igények beépítése és a hirdetési
              szövegek kézzel történő megírása.
            </p>
          </CenterRow>
          <Number fixed={img4} className="right fourth-row" />
          <CenterRow>
            <BlockTitle>Nagyra lősz, tűpontos célzással</BlockTitle>
            <p>
              A kulcsszavakat meghatározott termékparaméterek kombinációjaként
              definiáljuk. Pl: szín+márka+terméknév - így ha valaki rákeres
              arra, hogy “ezüst Michael Kors óra”, akkor a hirdetés pontosan
              ilyen paraméterű termékeket - esetünkben az ezüst színű Michael
              Kors órákat - fog kínálni.. Mivel a termékparaméterek szinte
              végtelen számú variánsát lefedjük, így nagy - pontosabban:
              rendkívül sok kicsi, de együtt nagy - célközönségre tudunk
              célozni, szinte tűpontosan.
            </p>
          </CenterRow>
          <Number fixed={img5} />
          <CenterRow>
            <BlockTitle>
              Személyre szabott hirdetési szöveg és érkezési oldal
            </BlockTitle>
            <p>
              A manuálisan megírott, konkrét terméktulajdonságokat tartalmazó
              hirdetések olyan landingre irányítják a felhasználót, ami az adott
              keresés szempontjából abszolút releváns. Így az előző példából
              kiindulva a látogató egy olyan szűrt találati oldalra érkezik,
              amely kizárólag az ezüst színű Michael Kors órákat fogja
              tartalmazni.
            </p>
          </CenterRow>
        </ContentContainer>
      </Container>
    </StyledSection>
  )
}

export default Reasons
