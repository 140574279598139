import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

import Background from "../../images/gridmarketing__bg__cta@2x-80.jpg"
import { ACCENT_COLOR } from "../../consts/color"

const StyledSection = styled.section`
  padding: 2.33333333rem 0;
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`
const Container = styled.div`
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;
  text-align: center;
`
const SectionMainTitle = styled.h2`
  color: #fff;
  font-weight: 300;
  font-size: 2.325em;
  margin-bottom: 1.85rem;
`
const SectionSecondaryTitle = styled.h3`
  color: #fff;
  font-size: 1.275em;
  text-transform: uppercase;
  margin-bottom: 1.85rem;
`
const Button = styled.a`
  display: inline-block;
  background-color: #fff;
  color: ${ACCENT_COLOR};
  font-size: 1.275em;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 700;
  padding: 1rem 1.875rem;
  transition: transform .2s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
`
const Mic = styled(Img)`
  margin-bottom: 1.875rem;
`

const Cta = ({ mic }) => {
  return (
    <StyledSection>
      <Container>
        <Mic fixed={mic} />
        <SectionMainTitle>
          Szeretnéd professzionális szintű, adatbázis-vezérelt Google Ads
          hirdetésekkel fellendíteni webshopod forgalmát?
        </SectionMainTitle>
        <SectionSecondaryTitle>
          Kérj személyre szabott ajánlatot!
        </SectionSecondaryTitle>
        <Button href="#kapcsolat">Ajánlatkérés</Button>
      </Container>
    </StyledSection>
  )
}

export default Cta
