import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/sections/hero"
import Education from "../components/sections/education"
import Comparison from "../components/sections/comparison"
import Cta from "../components/sections/cta"
import Recommend from "../components/sections/recommend"
import Reasons from "../components/sections/reasons"
import About from "../components/sections/about"
import Contact from "../components/sections/contact"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Főoldal" />
    <Hero img={data.heroImage.src.fluid} />
    <Education bulb={data.bulb.src.fixed} />
    <Comparison bulb={data.bulb.src.fixed} />
    <Cta mic={data.mic.src.fixed}/>
    <Reasons />
    <Recommend img={data.handshake.src.fluid} bullet={data.bullet.src.fixed} />
    <Cta mic={data.mic.src.fixed}/>
    <About img={data.about.src.fluid} bullet={data.bullet.src.fixed}/>
    <Contact/>
  </Layout>
)

export default IndexPage

export const pageQuery = graphql`
  query {
    heroImage: file(relativePath: { eq: "gridmarketing__img1@2x-80.jpg" }) {
      src: childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bulb: file(relativePath: { eq: "bulb.png" }) {
      src: childImageSharp {
        fixed(width: 40) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    mic: file(relativePath: { eq: "mic.png" }) {
      src: childImageSharp {
        fixed(width: 65) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    handshake: file(relativePath: { eq: "gridmarketing__img3@2x-80.jpg" }) {
      src: childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bullet: file(relativePath: { eq: "gridmarketing__bullet@2x.png" }) {
      src: childImageSharp {
        fixed(width: 16) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    about: file(relativePath: { eq: "gridmarketing__csapatkep.jpg" }) {
      src: childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
