import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { ACCENT_COLOR } from "../../consts/color"
import Table from "../table"

const StyledSection = styled.section`
  padding: 3.25rem 0;

  @media all and (min-width: 768px) {
    padding: 6.25rem 0;
  }
`
const Container = styled.div`
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;
`
const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2.5rem;
  margin-bottom:  3.25rem;

  @media all and (min-width: 769px) {
    margin-bottom:  6.25rem;
    grid-template-columns: 1fr 1fr;
  }
`
const SectionTitle = styled.h2`
  font-size: 1.9em;
  font-weight: 400;
  margin-bottom: 3.3125rem;
  text-align: center;

  @media all and (min-width: 768px) {
    font-size: 2.325em;
    margin-bottom: 5.3125rem;
  }
`
const BlockTitle = styled.h3`
  font-size: 1.375em;
  margin-bottom: 1rem;
`
const RoundBlock = styled.div`
  display: grid;
  grid-gap: 1.25rem;
  grid-template-columns: 1fr;
  color: #fff;
  background-color: ${ACCENT_COLOR};
  border-radius: 30px;
  padding: 1.875rem;

  @media all and (min-width: 768px) {
    grid-template-columns: 1fr 11fr;
  }
`
const Bulb = styled(Img)`
  justify-self: center;
`
const Strong = styled.span`
  font-weight: 700;
`
const TableHeader = styled.div`
  margin-bottom: 4.0625rem;
`
const TableTitle = styled.h3`
  font-size: 1.375em;
  font-weight: 700;
  margin-bottom: 1rem;
`

const Comparison = ({ bulb }) => {
  return (
    <StyledSection>
      <Container>
        <SectionTitle>
          Lépj szintet velünk webshopod ügyfélszerzésében!
        </SectionTitle>
        <ContentContainer>
          <div>
            <p>
              Google Ads hirdetéseket számos logika mentén lehet felépíteni, de
              minden esetben igaz, hogy hagyományos módon csak egy adott
              mélységig mehetünk le, nem törődhetünk külön-külön minden
              termékünk minden részletével. Mi történik akkor, ha a “fekete
              pamut gyerek póló”-ra keresők is fontosak?
            </p>
            <p>
              Hagyományos dinamikus keresési hirdetések (DSA) alkalmazásával
              könnyen és gyorsan összerakható egy alapvető termékszintű kampány,
              mégis be kell látnunk, hogy ennek a hirdetéstípusnak számos
              korlátja van.
            </p>
            <p>
              Az általunk egyedileg fejlesztett rendszer teljes mértékben
              kiküszöböli a DSA hiányosságait és{" "}
              <Strong>
                személyre szabott keresési hirdetéseket, tűpontos célzást
                biztosít, úgy, hogy közben a készletkövetés is biztosított.
              </Strong>
              ​
            </p>
          </div>
          <RoundBlock>
            <Bulb fixed={bulb} />
            <div>
              <BlockTitle>Mi is az a DSA hirdetés?</BlockTitle>
              <p>
                A dinamikus keresési hirdetések alkalmazásával a Google Ads
                rendszere feltérképezi weboldalad struktúráját, az ehhez tartozó
                lehetséges kulcsszókészletet és összepárosítja a leginkább
                releváns landoló weblapokkal. Ezt követően pedig legenerálja a
                hirdetési szövegeidet is, melynek címsora automatikusan
                tartalmazza a felhasználó által keresett kulcsszót, de a leíró
                sorok testreszabhatóak.
              </p>
            </div>
          </RoundBlock>
        </ContentContainer>
        <TableHeader>
          <TableTitle>Táblázat:</TableTitle>
          <p>
            Nézzük, mit nyújt a DSA hirdetés és ezzel szemben ​az{" "}
            <Strong>
              általunk egyedileg fejlesztett, adatvezérelt Google Ads keresési
              hirdetés​!
              <Strong />
            </Strong>
          </p>
        </TableHeader>
        <Table/>
      </Container>
    </StyledSection>
  )
}

export default Comparison
