import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

// import Bullet from '../../images/gridmarketing__bullet@2x.png'

const StyledSection = styled.section`
  padding: 4.375rem 0;
  background-color: #f2f2f2;
`
const Container = styled.div`
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;
`
const SectionTitle = styled.h2`
  font-size: 2.125em;
  font-weight: 400;
  margin-bottom: 3.125rem;
  text-align: center;
`
const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2.5rem;

  @media all and (min-width: 769px) {
    grid-template-columns: 1fr 1fr;
  }
`
const BlockTitle = styled.h3`
  font-size: 1.375em;
  font-weight: 700;
  margin-bottom: 1rem;
`
const List = styled.div`
  display: grid;
  grid-column-gap: 0.625rem;
  grid-row-gap: 0.625rem;
  grid-template-columns: 16px 1fr;
`
const ListItem = styled.p`
  margin-bottom: 0;
  /* margin-bottom: 10px; */

  /* &::before {
    content: '';
    display: inline-block;
    height: 16px;
    width: 16px;
    background-image: url(${Bullet});
    background-position: center;
    background-size: contain;
    padding-top: 3px;
    margin-right: 16px;
  } */
`

const Bullet = styled(Img)`
  margin-top: 3px;
`

const Recommend = ({ img, bullet }) => {
  return (
    <StyledSection id="kiknek-ajanljuk">
      <Container>
        <SectionTitle>
          Kinek ajánljuk az adatvezérelt Google Ads keresési hirdetéseket?
        </SectionTitle>
        <ContentContainer>
          <div>
            <BlockTitle>Mi vagyunk a főnyeremény céged számára, ha:</BlockTitle>
            <List>
              <Bullet fixed={bullet} />
              <ListItem>
                már kiforrott, megalapozott üzleti koncepcióval rendelkezel
              </ListItem>
              <Bullet fixed={bullet} />
              <ListItem>van webshopod</ListItem>
              <Bullet fixed={bullet} />
              <ListItem>
                széles termékpalettával dolgozol, ahol rendelkezésre állnak jól
                definiálható termékparaméterek
              </ListItem>
              <Bullet fixed={bullet} />
              <ListItem>
                a webshopod mögött aktív készletkezelés is van
              </ListItem>
              <Bullet fixed={bullet} />
              <ListItem>
                hajlandó vagy befektetni a Google hirdetési rendszerében
                ügyfélszerzés céljából.
              </ListItem>
            </List>
          </div>
          <Img fluid={img} alt="Mi vagyunk a főnyeremény céged számára"/>
        </ContentContainer>
      </Container>
    </StyledSection>
  )
}

export default Recommend
